import React from 'react'

import { social } from '../../../../../constants/social'
import * as S from './social-icons-styled'

export const SocialIcons = () => (
  <S.Wrapper>
    {Object.values(social).map(item => (

      <S.Link
        key={item.url}
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.Icon icon={item.fontAwesomeIcon} />
      </S.Link>

    ))}
  </S.Wrapper>
)