import React from 'react'

import colorLogoSvg from './assets/logo_color_horizontal.svg'
import whiteLogoSvg from './assets/logo_white_horizontal.svg'
import * as S from './logo-styled'

const ALT_TEXT = 'Global Grubbr Logo';

export const ColorLogo = () => (
  <S.Img
    alt={ALT_TEXT}
    src={colorLogoSvg}
  />
)

export const WhiteLogo = () => (
  <S.Img
    alt={ALT_TEXT}
    src={whiteLogoSvg}
  />
)