import styled from 'styled-components'
import { media } from '../../../constants/media';

export const Img = styled.img`
  width: 130px;
  height: 50px;
  @media ${media.medium.query} {
    width: 171px;
    height: 65px;
  }
`;