import styled, { createGlobalStyle } from 'styled-components'

import { media } from '../../../constants/media'

export const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;
    box-sizing: border-box;
  }

  *, ::after, ::before {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    background-color: ${props => props.theme.colors.rice};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageContainer = styled.div`
  font-family: ${props => props.theme.fonts.poppins};
  background-color: ${props => props.theme.colors.rice};
  width: 100%;
  min-height: 80vh;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50px;
  padding-bottom: 80px;
  text-align: center;

  @media ${media.medium.query} {
    max-width: ${media.medium.maxWidth};
    padding-top: 100px;
    padding-bottom: 130px;
    text-align: left;
  }
`;