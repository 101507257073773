import React from 'react'
import { Link } from 'gatsby'

import { SocialIcons } from './social-icons/social-icons-component'
import { WhiteLogo } from '../../../common/logo/logo-component'
import { ROUTES } from '../../../../constants/routes'
import * as S from './footer-styled'

export const Footer = () => {

  const year = (new Date()).getFullYear();

  return (
    <S.Footer>

      <S.LogoArea>

        <Link to={ROUTES.home.path}>
          <WhiteLogo />
        </Link>

      </S.LogoArea>

      <S.SocialArea>
        <SocialIcons />
      </S.SocialArea>

      <S.TextArea>

        <S.Copyright>&#169; {year} Just Right Solutions LLC</S.Copyright>

        <S.Spacer>•</S.Spacer>

        <S.Link href={ROUTES.privacy.path}>
          {ROUTES.privacy.text}
        </S.Link>

        <S.Spacer>•</S.Spacer>

        <S.Link href={ROUTES.contact.path}>
          {ROUTES.contact.text}
        </S.Link>

      </S.TextArea>

    </S.Footer>
  )

}