import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'

import { Navbar } from './navbar/navbar-component'
import { Footer } from './footer/footer-component'
import { theme } from '../../../constants/theme'
import * as S from './layout-styled'

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link href={`https://fonts.googleapis.com/css?family=Poppins|Solway:400,500,700,800&display=swap`} rel="stylesheet" />
      <script data-ad-client="ca-pub-1381379653556828" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
    </Helmet>

    <ThemeProvider theme={theme}>

      <S.GlobalStyle />

      <S.Main>

        <Navbar />

        <S.PageContainer>
          {children}
        </S.PageContainer>

        <Footer />

      </S.Main>

    </ThemeProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
