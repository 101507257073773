import React, { useState } from 'react'
import { Link } from 'gatsby'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { ColorLogo, WhiteLogo } from '../../../common/logo/logo-component'
import { social } from '../../../../constants/social'
import { ROUTES } from '../../../../constants/routes'
import * as S from './navbar-styled'

export const Navbar = () => {
  const [isMobileNavHidden, setIsMobileNavHidden] = useState(true);
  const toggleNavMenu = () => {
    setIsMobileNavHidden(!isMobileNavHidden)
  };

  return (
    <S.Wrapper>

      <S.Brand>

        <Link to={ROUTES.home.path}>
          <ColorLogo />
        </Link>

      </S.Brand>

      <S.Nav>

        <S.NavLink to={ROUTES.home.path}>
          {ROUTES.home.text}
        </S.NavLink>

        <S.NavLink to={ROUTES.recipe.path}>
          {ROUTES.recipe.text}
        </S.NavLink>

        <S.NavLink to={ROUTES.about.path}>
          {ROUTES.about.text}
        </S.NavLink>

        <S.NavLink to={ROUTES.contact.path}>
          {ROUTES.contact.text}
        </S.NavLink>

      </S.Nav>

      <S.ToggleButton onClick={toggleNavMenu}>
        <S.IconBar></S.IconBar>
        <S.IconBar></S.IconBar>
        <S.IconBar></S.IconBar>
      </S.ToggleButton>

      <S.MobileNavWrapper hide={isMobileNavHidden}>

        <S.MobileNavHeader>

          <S.Brand>
            <Link to={ROUTES.home.path}>
              <WhiteLogo />
            </Link>
          </S.Brand>

          <S.CloseLink onClick={toggleNavMenu}>
            <S.CloseIcon icon={faTimes} />
          </S.CloseLink>

        </S.MobileNavHeader>

        <S.MobileNav>

          <S.MobileNavLink to={ROUTES.home.path}>
            {ROUTES.home.text}
          </S.MobileNavLink>

          <S.MobileNavLink to={ROUTES.recipe.path}>
            {ROUTES.recipe.text}
          </S.MobileNavLink>

          <S.MobileNavLink to={ROUTES.about.path}>
            {ROUTES.about.text}
          </S.MobileNavLink>

          <S.MobileNavLink to={ROUTES.contact.path}>
            {ROUTES.contact.text}
          </S.MobileNavLink>

        </S.MobileNav>

        <S.MobileNavFooter>

          {Object.values(social).map(item => (
            <S.SocialIconLink
              key={item.url}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SocialIcon icon={item.fontAwesomeIcon} />
            </S.SocialIconLink>
          ))}

        </S.MobileNavFooter>
      </S.MobileNavWrapper>

    </S.Wrapper>
  );
}