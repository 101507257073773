import styled from 'styled-components'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { media } from '../../../../constants/media'

export const Wrapper = styled.div`
  background-color: transparent;

  width: 100%;
  margin-bottom: 2rem;

  height: ${media.small.navbarHeight};
  @media ${media.medium.query} {
    height: ${media.medium.navbarHeight};
  }
`;

export const Brand = styled.div`
  float: left;
  & a {
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
  margin-top: 22px;
  margin-left: 23px;
  @media ${media.medium.query} {
    margin-top: 32px;
    margin-left: 45px;
  }
`;

export const Nav = styled.nav`
  display: none;
  @media ${media.medium.query} {
    display: block;
    float: right;
    position: relative;
    margin-top: 42px;
    margin-right: 50px;
  }
`;

export const NavLink = styled(Link)`
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  padding: 0;

  font-size: 1.8rem;
  color: ${props => props.theme.colors.nori};
  font-family: ${props => props.theme.fonts.poppins};
  font-weight: 800;
  line-height: 3rem;
  letter-spacing: 0.76px;
  text-transform: uppercase;

  display: inline-block;
  position: relative;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  background-color: transparent;

  :hover {
    color: ${props => props.theme.colors.salmon};
    text-decoration: none;
  }
`;

export const ToggleButton = styled.button`
  float: right;
  margin-top: 27px;
  margin-right: 28px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;

  @media ${media.medium.query} {
    display: none;
  }
`;

export const CloseLink = styled.a`
  text-decoration: none;
  font-family: ${props => props.theme.fonts.poppins};
  margin-top: 34px;
  margin-right: 29px;
  font-size: 2.5rem;
  float: right;
  color: white;
`;

export const CloseIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 3rem;
`;

export const IconBar = styled.span`
  display: block;
  width: 27px;
  height: 1px;
  border: solid 1.5px ${props => props.theme.colors.nori};
  margin: 6px 0;
`;

export const MobileNavWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: ${props => props.theme.colors.nori};
  display: ${props => props.hide ? 'none' : 'grid'};
  grid-template-areas:
    "header"
    "nav"
    "footer";
  grid-template-columns: 1fr;
  grid-template-rows: 100px 1fr 75px;
`;

export const MobileNavHeader = styled.div`
  grid-area: header;
`;

export const MobileNavFooter = styled.div`
  grid-area: footer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 20px 12px;
  }
`;

export const SocialIconLink = styled.a`
  text-decoration: none;
`;

export const SocialIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 3rem;
`;

export const MobileNav = styled.nav`
  grid-area: nav;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: scroll;
  min-width: 200px;
`;

export const MobileNavLink = styled(Link)`
  margin-bottom: 35px;
  font-size: 2.8rem;
  line-height: 3.1rem;
  color: white;
  font-family: ${props => props.theme.fonts.solway};
  text-decoration: none;
  background-color: transparent;
`;
