import styled from 'styled-components'

import { media } from '../../../../constants/media'

export const Footer = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 50px 75px;
  grid-template-areas:
    "social"
    "text"
    "logo";
  @media ${media.medium.query} {
    grid-template-columns: 175px 1fr;
    grid-template-rows: 50px 50px;
    grid-template-areas:
      "logo social"
      "logo text";
  }

  background-color: ${props => props.theme.colors.nori};
  padding: 50px 0;

  font-family: ${props => props.theme.fonts.poppins};
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: white;
`;

export const LogoArea = styled.div`
  grid-area: logo;
  @media ${media.medium.query} {
    margin-left: 45px;
    justify-self: start;
  }
`;

export const SocialArea = styled.div`
  grid-area: social;
  @media ${media.medium.query} {
    margin-right: 45px;
    justify-self: end;
  }
`;

export const TextArea = styled.div`
  grid-area: text;
  @media ${media.medium.query} {
    margin-right: 45px;
    justify-self: end;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 0 5px;
  }
`;

export const Copyright = styled.div`
`;

export const Link = styled.a`
  text-decoration: none;
  color: white;
  :hover {
    text-decoration: underline;
  }
`;

export const Spacer = styled.div`
  color: white;
`;