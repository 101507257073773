const media = {}

const SM_MAX_WIDTH = 375;
const SM_NAVBAR_HEIGHT = 71;
const SM_FOOTER_HEIGHT = 300;

const MD_MIN_WIDTH = 768;
const MD_SNIPPET_IMG_COL_WIDTH = 285;
const MD_SNIPPET_TEXT_COL_WIDTH = 575;
const MD_MAX_WIDTH = MD_SNIPPET_IMG_COL_WIDTH + MD_SNIPPET_TEXT_COL_WIDTH;
const MD_NAVBAR_HEIGHT = 97;
const MD_FOOTER_HEIGHT = 200;

const LG_MIN_WIDTH = 1024;

media.small = {
  maxWidth: `${SM_MAX_WIDTH}px`,
  navbarHeight: `${SM_NAVBAR_HEIGHT}px`,
  footerHeight: `${SM_FOOTER_HEIGHT}px`,
}

media.medium = {
  query: `(min-width: ${MD_MIN_WIDTH}px)`,
  maxWidth: `${MD_MAX_WIDTH}px`,
  snippetColumns: `${MD_SNIPPET_IMG_COL_WIDTH}px ${MD_SNIPPET_TEXT_COL_WIDTH}px`,
  navbarHeight: `${MD_NAVBAR_HEIGHT}px`,
  footerHeight: `${MD_FOOTER_HEIGHT}px`,
}

media.large = {
  query: `(min-width: ${LG_MIN_WIDTH}px)`,
}

export { media }