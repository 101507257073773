import {
  faFacebookSquare,
  faInstagram,
  faTwitter,
  faPinterest
} from '@fortawesome/free-brands-svg-icons'

export const social = {

  facebook: {
    url: 'https://www.facebook.com/GlobalGrubbr',
    fontAwesomeIcon: faFacebookSquare,
  },

  instagram: {
    url: 'https://www.instagram.com/GlobalGrubbr',
    hashTagUrl: 'https://www.instagram.com/explore/tags/globalgrubbr',
    fontAwesomeIcon: faInstagram,
  },

  twitter: {
    url: 'https://www.twitter.com/GlobalGrubbr',
    fontAwesomeIcon: faTwitter,
  },

  pinterest: {
    url: 'https://www.pinterest.com/GlobalGrubbr',
    fontAwesomeIcon: faPinterest,
  },

}