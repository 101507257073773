const theme = {}

theme.colors = {
  salmon:       '#d66e54',
  rice:         '#f8edde',
  nori:         '#21261a',
  pacific:      '#204797',
  white:        '#ffffff',
  black:        '#000000',
  red:          'red',
  gray:         '#b4b6ba',
}

theme.fonts = {
  poppins:    "'Poppins', sans-serif",
  solway:     "'Solway', serif",
}

theme.concepts = {
  /* Colors */
  mainBrandColor:           theme.colors.salmon,
  secondaryBrandColor:      theme.colors.nori,
  mainAccentColor:          theme.colors.nori,
  secondaryAccentColor:     theme.colors.rice,
  mainBackgroundColor:      theme.colors.rice,
  borderColor:              theme.colors.nori,
  disabledColor:            theme.colors.gray,
  errorColor:               theme.colors.red,

  /* Fonts */
  defaultFont:      theme.fonts.poppins,
  softTitleFont:    theme.fonts.solway,
  strongTitleFont:  theme.fonts.solway,
}

export { theme }