export const ROUTES = {
  home: {
    path: '/',
    text: 'Home',
  },
  recipe: {
    path: '/recipe',
    text: 'Recipes',
  },
  about: {
    path: '/about',
    text: 'About',
  },
  contact: {
    path: '/contact',
    text: 'Contact',
  },
  privacy: {
    path: '/privacy',
    text: 'Privacy',
  },
  confirm: {
    path: '/confirm',
  },
  unsubscribe: {
    path: '/unsubscribe',
  },
}