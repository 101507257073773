import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Link = styled.a`
  text-decoration: none;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & > * {
    margin: 20px 12px;
  }
`;

export const Icon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 3rem;
`;
